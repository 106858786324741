import React from "react";
import { Helmet } from "react-helmet";

export const SEO = ({ title }) => {
  const seo = {
    title: title || "Hyfen8 - Discover the Untapped Power in your Business",
  };

  return (
    <div>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content="Hyfen8 - Discover the Untapped Power in your Business" />
        <meta name="og:title" property="og:title" content="Hyfen8 - Discover the Untapped Power in your Business"></meta>
        <meta name="twitter:card" content="Hyfen8 - Discover the Untapped Power in your Business"></meta>
        <meta property="og:image" content="/og-image.png" />
      </Helmet>
    </div>
  );
};

export default SEO;
