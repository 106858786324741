import React from "react";
import { useRecoilState } from "recoil";
import { collapsedState } from "../../utils/recoil-atoms";
import { Link, useStaticQuery, graphql } from "gatsby";
import logo from "../../assets/images/hyfen/logo.png";

const Navbar = () => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          portalUrl
          portalUrlRegister
        }
      }
    }
  `);
  React.useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
  });

  const classOne = collapsed ? "collapse navbar-collapse" : "collapse navbar-collapse show";
  const classTwo = collapsed ? "navbar-toggler navbar-toggler-right collapsed" : "navbar-toggler navbar-toggler-right";

  return (
    <React.Fragment>
      <div id="navbar" className="navbar-area">
        <div className="tarn-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light mr-2 ml-2">
              <Link to="/" onClick={() => setCollapsed(true)} className="navbar-brand">
                <img src={logo} alt="logo" />
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navtoggle-text">Menu</span>
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/about" activeClassName="active" onClick={() => setCollapsed(true)} className="nav-link line">
                      About Hyfen8
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/framework-intangibles" activeClassName="active" onClick={() => setCollapsed(true)} className="nav-link line">
                      Framework
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/blog" activeClassName="active" onClick={() => setCollapsed(true)} className="nav-link line">
                      Blog
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/data-privacy" activeClassName="active" onClick={() => setCollapsed(true)} className="nav-link line">
                      Data &amp; Privacy
                    </Link>
                  </li>
                </ul>
                
                <div className="others-option d-flex align-items-center" style={{ visibility: "hidden" }}>
                  <div className="option-item d-flex align-items-center">
                    <div className="navbar-nav">
                      <div className="nav-item">
                        <Link
                          to={data.site.siteMetadata.portalUrl}
                          target="/blank"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link line"
                        >
                          Log In
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="option-item d-flex align-items-center">
                    <button
                      activeClassName="active"
                      onClick={() => {
                        window.location = `${window.location.origin}/signup`;
                        setCollapsed(true);
                      }}
                      className="default-btn"
                    >
                      Sign Up <span className="effect"></span>
                    </button>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
