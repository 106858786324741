import React from "react";
import { RecoilRoot } from "recoil";
import GoTop from "./GoTop";
import Seo from "./SEO";

const Layout = ({ title, children }) => {
  return (
    <RecoilRoot>
      <Seo title={title} />
      {children}
      <GoTop scrollStepInPx="100" delayInMs="10.50" />
    </RecoilRoot>
  );
};

export default Layout;
